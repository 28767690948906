import { AxiosPromise } from "axios";
import axiosClient from "@/axios";
import { PickupPointsResponse } from "@/models/PickupPointsResponse";

const pickupPointService = {
  getPickupPoints(
    countryId?: string | number,
    supplierId?: string | number,
    zipCode?: string | number,
    address?: string,
    accountDetails?: any
  ): AxiosPromise<PickupPointsResponse> {
    const baseURL = axiosClient?.defaults?.baseURL?.replace(
      "/api/v1",
      "/customer_api/v1"
    );
    return axiosClient.get(
      `/pudo_points?country_id=${countryId}&supplier=${supplierId}&zip_code=${zipCode}&address_first_line=${address}`,
      {
        baseURL: baseURL,
        headers: {
          "Content-Type": "application/json",
          "Authorization-Token": accountDetails.customer_api_secret,
        },
      }
    );
  },
};

export default pickupPointService;
